import * as React from 'react'
import { Link } from 'gatsby'

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        Helder S Ribeiro © {new Date().getFullYear()} ·{' '}
        <a href="https://twitter.com/agentofuser">twitter</a> ·{' '}
        <a href="https://github.com/agentofuser">github</a> ·{' '}
        <a href="https://www.linkedin.com/in/hsribei/">linkedin</a> ·{' '}
        <a href="https://dev.to/agentofuser">dev.to</a> ·{' '}
        <a href="https://www.youtube.com/channel/UCKe6uiKWy3nq-GBAudbt7kQ">
          youtube
        </a>{' '}
        ·{' '}
        <a href="https://app.proofofhumanity.id/profile/0x0822D5B1F3577628e6653113A37cDbe0c9441c26?network=mainnet">
          PoH
        </a>{' '}
        · <a href="https://keybase.io/agentofuser">keybase</a> ·{' '}
        <a href="mailto:hi@agentofuser.com">email</a> ·{' '}
        <a href="https://footnotes.agentofuser.com">footnotes</a> ·{' '}
        <a href="https://aou.vc/subscribe">newsletter</a>
      </footer>
    </div>
  )
}

export default Layout
